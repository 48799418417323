@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;0,800;1,400;1,700;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  background-color: white;
  max-width: 60vw;
  min-width: 700px;
  margin: 5vh auto;
  padding: 2rem;
  border-radius: 25px;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 14px 17px 12px 3px rgba(48, 49, 61, 0.64);
}

html {
  background: linear-gradient(215deg, #ff8b8f 0%, #ffff5b 23%, #63ffd5 49%, #ec7bff 80%);
  background-size: cover;
}

h2 {
  margin: 0px;
  border: 0px;
  font-size: 2rem;
  font-weight: 800;
  background: linear-gradient(215deg, #ff5258 0%, #ebeb40 23%, #39c49f 49%, #b946cc 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h3 {
  margin: 0px;
  border: 0px;
}

h4 {
  margin: 0px;
  margin-top: 10px;
  border: 0px;
}

h5 {
  margin: 0px;
  margin-top: 10px;
  border: 0px;
  font-size: 1rem;
  line-height: 1rem;
}

.cityCardContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: 10px;
}

.cityCardContainer .cityCard {
  padding: 10px 20px;
  -webkit-align-content: center;
          align-content: center;
  background-color: white;
  border-radius: 25px;
  box-shadow: 4px 4px 12px 3px rgba(188, 188, 193, 0.64);
}

.cityCardContainer .cityCard .cityInput {
  padding: 10px;
}

.cityCardContainer .cityCard .cityInput input {
  padding: 3px 5px;
}

.cityCardContainer .cityCard .cityInput button {
  background-color: #b875ff;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 5px 20px;
  margin-left: 10px;
}

.cityCardContainer .cityCard .cityInput button:hover {
  background-color: #974ee6;
  cursor: pointer;
}

.cityCardContainer .cityCard .cityInput button:active {
  background-color: #7433b9;
}

.cityCardContainer .cityCard .coords {
  list-style: none;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
          align-self: center;
  padding: 0px;
}

.section {
  padding: 10px 50px;
}

.input {
  margin: 10px;
}

.disclaimer {
  margin: 10px 20px;
  font-size: 0.8rem;
}

span {
  font-weight: 600;
  font-size: 1.5rem;
  padding: 0px 10px;
  border-radius: 10px;
}

span:hover {
  background: linear-gradient(215deg, #ff8b8f 0%, #ffff5b 23%, #63ffd5 49%, #ec7bff 80%);
}
/*# sourceMappingURL=App.css.map */
